<template>
  <section class="main-footer clear">
    <div class="w">
      <div class="content lf">
        <p class="content-s">
          <span>{{timeinfo.theKey}}{{ timeinfo.theValue }}</span>
          <span>{{help.theKey}}{{ help.theValue }}</span>
        </p>
        <p class="content-s">
          <span>网站备案/许可证号： <a href="https://beian.miit.gov.cn/" target="_blank">{{ result.theValue }}</a> </span>
        </p>
      </div>
      <div class="qrcode lr">
        <img v-if="image" :src="image" alt="" />
        <img v-else src="/image/qrcode.png" alt="" />
        <img v-if="images" :src="images" alt="" />
        <img v-else src="/image/qrcode.png" alt="" />
      </div>
      <div class="complaint-tel lr">
        <i>投诉电话</i>
        <p>{{ complaint.theValue }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { loadConfig, loadPictureConfig } from "@/api/api";
import { getAction } from "@/api/manage";
export default {
  created() {
    this.loadData();
  },
  data() {
    return {
      result: "",
      image: "",
      images: "",
      timeinfo: "",
      help: "",
      complaint: "",
    };
  },
  methods: {
    loadData() {
      loadConfig({
        rule: "record_number",
      }).then((res) => {
        if (res.success) {
          this.result = res.result;
        }
      });
      loadConfig({
        rule: "adv_tel",
      }).then((res) => {
        if (res.success) {
          this.timeinfo = res.result;
        }
      });
      loadConfig({
        rule: "rec_tel",
      }).then((res) => {
        if (res.success) {
          this.help = res.result;
        }
      });
      loadConfig({
        rule: "complaint_tel",
      }).then((res) => {
        if (res.success) {
          this.complaint = res.result;
        }
      });
      loadPictureConfig({
        rule: "footer_qrcode",
      }).then((res) => {
        if (res.success) {
          this.image = `/jeecg-boot/${res.result.theLocal}`;
        }
      });
      loadPictureConfig({
        rule: "footer_qrcodes",
      }).then((res) => {
        if (res.success) {
          this.images = `/jeecg-boot/${res.result.theLocal}`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-footer {
  height: 152px;
  background-color: #515151;
  .content {
    margin-top: 50px;
    color: #feffff;
    .content-s {
      margin-bottom: 6px;
      span {
        margin-right: 20px;
      }
      a{
        margin-right: 20px;
        color: #feffff;
        transition: all .6s;
        &:hover{
          color:#6EB400;
        }
      }
    }
  }
  .qrcode {
    margin-top: 35px;
    img {
      width: 73px;
      height: 73px;
      margin-left: 17px;
    }
  }
  .complaint-tel {
    margin-top: 49px;
    color: #ff933c;
    font-size: 18px;
    margin-right: 87px;
  }
}
</style>
