import { getAction } from './manage.js'

/* 通过规则查询单页 */
export function loadSingleConfig(param) {
    return getAction('/one/oneConfig/getInfoByRule', param).then(
        res => {
            if (res.success) {
                return res;
            }
        }
    );
}
/* 通过规则查询图片 */
export function loadPictureConfig(param) {
    return getAction('/photo/sysPhoto/queryByRule', param).then(
        res => {
            if (res.success) {
                return res;
            }
        }
    );
}
/* 通过规则查询视频 */
export function loadVideoConfig(param) {
    return getAction('/video/sysVideo/queryByRule', param).then(
        res => {
            if (res.success) {
                return res;
            }
        }
    );
}
/* 通过规则查询配置 */
export function loadConfig(param) {
    return getAction('/cnf/sysConf/queryByRule', param).then(
        res => {
            if (res.success) {
                return res;
            }
        }
    );
}