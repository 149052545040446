<template>
    <div class="tou">
        <el-dialog title="注册" :visible.sync="visibles">
            <el-form
                ref="form"
                label-position="right"
                :model="form"
                :rules="rules"
                label-width="80px"
            >
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        type="password"
                        v-model="form.password"
                        autocomplete="off"
                        placeholder="请输入密码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input
                        type="password"
                        v-model="form.checkPass"
                        autocomplete="off"
                        placeholder="请再次输入密码"
                    ></el-input>
                </el-form-item>
                <el-button type="primary" @click="onSubmitAdd('form')">立即注册</el-button>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { addUser, login } from '@/api/login.js'
export default {
    name: "Register",
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.form.checkPass !== "") {
                    this.$refs.form.validateField("checkPass");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.form.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            visibles: false,
            form: {
                username: "",
                password: "",
                checkPass: "",
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "用户名不能为空",
                        trigger: "change",
                    },
                ],
                password: [
                    {
                        required: true,
						validator: validatePass, 
						trigger: 'change'
                    },
                ],
                checkPass: [
                    {
                        required: true,
						validator: validatePass2, 
						trigger: 'change'
                    },
                ],
            },
        };
    },
    methods: {
        openMssages() {
			this.visibles = true;
			this.form = {}
        },
        //注册
        onSubmitAdd(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    addUser(this.form).then(res => {
                        if (res.success) {
                            login({
                                username: this.form.username,
                                password: this.form.password,
                            }).then((res) => {
                                this.$message.success("注册成功");
                                // 储存token
                                localStorage.setItem("token", res.result.token);
                                // 储存用户信息
                                localStorage.setItem("userInfo",JSON.stringify(res.result.userInfo));
                                // 储存用户userid
							    localStorage.setItem("userid", res.result.userInfo.id);
                                this.$router.push("/user");
                            });
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.tou {
    ::v-deep.el-dialog__title {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #999999;
    }
    ::v-deep.el-input__inner {
        width: 312px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px #dbdbdb;
    }
    ::v-deep.el-dialog {
        width: 496px;
    }
    ::v-deep.el-dialog__footer {
        text-align: center;
    }
    .el-button--primary {
        width: 212px;
        height: 44px;
        background-color: #6EB400;
        border-color: #6EB400;
        border-radius: 3px;
        margin-left: 80px;
    }
    .denlu {
        margin-left: 80px;

        font-family: MicrosoftYaHeiLight;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0px;
        color: #6EB400;
    }
    .dialog-footer {
        margin-top: -16px;
        margin-left: -81px;
    }
    .text:hover {
        cursor: pointer;
    }
}
</style>
