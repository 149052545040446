<template>
  <section class="wap-main-header clear">
    <div class="header-box w">
      <div class="logo lf">
        <router-link to="/">
        <img src="@/assets/image/main/logo.png" alt="" class="logo-img" />
        </router-link>
      </div>
      <nav class="main-nav lf">
        <el-button
          @click="drawer = true"
          type="primary"
          style="margin-left: 16px;"
        >
          <i class="el-icon-s-unfold"></i>导航
        </el-button>
        <el-drawer
          :visible.sync="drawer"
          :with-header="false"
          style="width: 110%;"
          :direction="direction"
        >
          <el-menu
            :default-active="activeIndex"
            mode="horizontal"
            background-color="#6EB400"
            text-color="#fff"
            active-text-color="#FFF"
            router
          >
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-menu-item index="/">首页</el-menu-item>
              <el-menu-item index="/survey">崇信概况</el-menu-item>
              <el-collapse-item title="景点与路线" name="1">
                <router-link to="/scenicspot">
                  <div class="opus">旅游景点</div>
                </router-link>
                <router-link to="/touristrou">
                  <div class="opus">旅游路线</div>
                </router-link>
                <router-link to="/tourismbus">
                  <div class="opus">旅游商业</div>
                </router-link>
                <router-link to="/specialtour">
                  <div class="opus">特种旅游</div>
                </router-link>
              </el-collapse-item>
              <el-collapse-item title="旅游文化" name="2">
                <router-link to="/tourism/list/1">
                  <div class="opus">崇信文物</div>
                </router-link>
                <router-link to="/tourism/list/2">
                  <div class="opus">崇信名人</div>
                </router-link>
                <router-link to="/tourism/list/3">
                  <div class="opus">崇信民俗</div>
                </router-link>
                <router-link to="/tourism/list/4">
                  <div class="opus">崇信名吃</div>
                </router-link>
                <router-link to="/tourism/list/5">
                  <div class="opus">崇信特产</div>
                </router-link>
                <router-link to="/tourism/list/6">
                  <div class="opus">旅游文学</div>
                </router-link>
              </el-collapse-item>
              <el-collapse-item title="旅游管理" name="3">
                <router-link to="/tourismmana">
                  <div class="opus">旅游管理机构</div>
                </router-link>
                <router-link to="/tourismasso">
                  <div class="opus">旅游协会</div>
                </router-link>
              </el-collapse-item>
              <el-collapse-item title="旅游企业" name="4">
                <router-link to="/hotel">
                  <div class="opus">旅游饭店</div>
                </router-link>
                <!-- <router-link to="5-2">
                  <div class="opus">旅游交通</div>
                </router-link> -->
                <router-link to="/travelagency">
                  <div class="opus">旅行社</div>
                </router-link>
                <router-link to="/travelshopping">
                  <div class="opus">旅游购物</div>
                </router-link>
                <router-link to="/entertainment">
                  <div class="opus">娱乐设施</div>
                </router-link>
              </el-collapse-item>
              <el-collapse-item title="信息查询" name="5">
                <router-link to="/touristinfo">
                  <div class="opus">旅游须知</div>
                </router-link>
                <router-link to="/dynamic">
                  <div class="opus">旅游动态</div>
                </router-link>
                <router-link to="/festival">
                  <div class="opus">旅游节庆</div>
                </router-link>
                <router-link to="/strategy">
                  <div class="opus">虚拟旅游</div>
                </router-link>
                <router-link to="/traffic">
                  <div class="opus">交通指南</div>
                </router-link>
                <router-link to="/meteor">
                  <div class="opus">气象服务</div>
                </router-link>
                <router-link to="/ticketprice">
                  <div class="opus">门票价格</div>
                </router-link>
                <router-link to="/travelmap">
                  <div class="opus">旅游地图</div>
                </router-link>
                <router-link to="/books">
                  <div class="opus">书籍光碟</div>
                </router-link>
              </el-collapse-item>
                <div class="login-regir lr">
                  <div
                    v-if="!islogin"
                    style="padding-top: 7px;margin-right: 18px;"
                  >
                    <span class="text" @click="openRegiser">注册</span>
                    <el-divider direction="vertical"></el-divider>
                    <span class="text" @click="openLogin">登录</span>
                  </div>
                  <div v-else>
                    <div class="userinfo clear">
                      <img
                        v-if="form.avatar"
                        :src="`/jeecg-boot/${form.avatar}`"
                        alt="头像"
                        class="touxiang clear"
                      />
                      <img
                        v-else
                        src="@/assets/image/user/avatar.jpg"
                        alt="头像"
                        class="touxiang clear"
                      />
                      <div class="mzi clear">
                        <el-dropdown>
                          <span class="el-dropdown-link">
                            {{ form.username}}<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <router-link to="/user">
                              <el-dropdown-item>基本信息</el-dropdown-item>
                            </router-link>
                            <router-link to="/user/mtravels"
                              ><el-dropdown-item
                                >我的游记</el-dropdown-item
                              ></router-link
                            >
                            <router-link to="/user/writeadd">
                              <el-dropdown-item>我要写游记</el-dropdown-item>
                            </router-link>
                            <el-dropdown-item @click.native="loginOut"
                              >退出</el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
            </el-collapse>
          </el-menu>
        </el-drawer>
      </nav>
    </div>
    <Register ref="register" />
    <Login ref="login" />
  </section>
</template>

<script>
import Register from "@/components/Register";
import Login from "@/components/Login";
import { logout } from "@/api/login.js";
export default {
  created() {
    let token = localStorage.getItem("token");
    let userinfo = localStorage.getItem("userInfo");
    if (token) {
      this.islogin = true;
    }
    if (userinfo) {
      this.form = JSON.parse(userinfo);
    }
  },
  components: {
    Register,
    Login,
  },
  data() {
    return {
      direction: "ltr",
      form: {},
      activeIndex: "1",
      islogin: false,
      drawer: false,
      innerDrawer: false,
      innerDrawe: false,
      innerDrawes: false,
      innerDrawee: false,
      innerDrawef: false,
    };
  },
  methods: {
    openRegiser() {
      this.$refs.register.visibles = true;
    },
    openLogin() {
      this.$refs.login.visibles = true;
    },
    loginOut() {
      const token = localStorage.getItem("token");
      let _this = this;
      logout(token).then((res) => {
        if (res.success) {
          _this.$message.success(res.message);
          localStorage.clear();
          _this.$router.push("/");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wap-main-header {
  height: 65px;
  background-color: #fff;
  box-shadow: 0px 0px 11px 9px #cccccc47;
  .header-box {
   padding-top: 13px;
    .main-nav {
      ::v-deep.el-menu--horizontal {
        border-bottom: 0;
        & > .el-menu-item {
          height: 40px;
          line-height: 40px;
          border-bottom: 0;
          font-size: 16px;
          &:hover {
            background-color: #6EB400 !important;
          }
        }
        & > .el-submenu {
          &:hover {
            .el-submenu__title {
              background-color: #6EB400 !important;
            }
          }
          .el-submenu__title {
            height: 40px;
            line-height: 40px;
            border-bottom: 0;
            font-size: 16px;
            padding: 0 16px;
            i {
              display: none;
            }
          }
        }
      }
      .el-button--primary{
            color: #000;
            background-color: #fff;
            border-color: #fff;
      }
    }
    .login-regir {
      padding-top: 10px;
    margin-right: 31px;
    margin-bottom: 10px;
      .el-divider {
        background-color: #fff;
        height: 16px;
        top: -2px;
      }
      .text {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .userinfo {
    .touxiang {
      width: 34px;
      height: 34px;
      border-radius: 15px;
      margin-right: 10px;
    }
    .mzi {
      float: right;
      margin-top: 5px;
      .el-dropdown {
        color: #fff;
      }
    }
  }
}

.text:hover {
  cursor: pointer;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ecf5ff;
  color: #6EB400;
}
.wap-main-header .header-box .main-nav[data-v-14a32e46] .el-menu--horizontal {
  height: 100%;
  width: 100%;
}
.el-button--primary {
  color: #fff;
  background-color: #6EB400;
  border-color: #6EB400;
  width: 76px;
}
.wap-main-header .header-box .main-nav[data-v-14a32e46] {
  float: right;
  margin-top: -56px;
}
/deep/.el-drawer__open .el-drawer.ltr {
  background: #6EB400;
  width: 40% !important;
}
.lf {
      float: right;
    margin-top: -43px;
}
::v-deep.wap-main-header
  .header-box
  .main-nav[data-v-14a32e46]
  .el-menu--horizontal
  > .el-submenu
  .el-submenu__title {
  display: none;
}
.wap-main-header
  .header-box
  .main-nav[data-v-14a32e46]
  .el-menu--horizontal
  > .el-menu-item {
  width: 116%;
}
/deep/.el-collapse-item__header {
  background-color: #6EB400 !important;
  color: #fff !important;
  border-bottom: none;
  margin-left: 20px;
  font-size: 14px;
  height: 56px;
}
/deep/.el-collapse-item__content {
  padding-bottom: 0px;
  font-size: 13px;
}
/deep/.el-collapse-item__wrap {
  background-color: #538800;
  border-bottom: none;
}
.el-collapse {
  border-bottom: none;
}
.opus {
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #fff !important;
}
 /deep/.el-drawer__body {

    overflow: scroll;
}
.el-dropdown-menu {
    background-color: #538800;
    border: none;
    
}
.el-dropdown-menu__item {
    color: #ffffff;
}
::v-deep.el-popper[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #538800;
}
::v-deep.el-popper[x-placement^=bottom] .popper__arrow::after {

    border-bottom-color: #538800 ;
}
.logo-img{
  width: 70%;
  margin-left: 10px;
  margin-top: 38px;
}
</style>
